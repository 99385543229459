import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as html2pdf from 'html2pdf.js';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentPopupComponent } from 'src/app/main/modules/sell-management/components/payment-popup/payment-popup.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatMenu } from '@angular/material/menu';
import Chart from 'chart.js/auto';
import { MainService } from 'src/app/main/services/main.service';
import { DateAdapter } from '@angular/material/core';
import { SharedService } from '../../services/shared-service/shared.service';
import { SellService } from 'src/app/main/modules/sell-management/service/sell.service';
import { AlertService } from '../../services/alert.service';
import { AuthService } from 'src/app/authentication/services/auth.service';

@Component({
  selector: 'app-name-sorting',
  templateUrl: './name-sorting.component.html',
  styleUrls: ['./name-sorting.component.scss']
})
export class NameSortingComponent {


  @ViewChild(MatSelect) matSelect: MatSelect;
  @ViewChild(MatMenu) menu: MatMenu;
  orgId: any;

  currentDate: Date;
  @Input() type: any;
  @Input() from: any;
  @Input() refreshCall: EventEmitter<void>;
  @Output() dateApplied = new EventEmitter<void>();

  range: any;
  csvStartDate: any = '';
  csvEndDate: any = '';
  cusEnd: Date;
  cusStart: Date;
  totalData: any = null;
  tableDate: any = null;
  selectedOption: string;
  DatepickOpen: boolean = false;
  isNameApply: boolean = false;
  payload = {
    sort: '',
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    public dialog: MatDialog,
    public mainService: MainService,
    private dateAdapter: DateAdapter<Date>,
    private sharedService: SharedService,
    public sellService: SellService,

  ) {
    this.orgId = this.authService.getId();
    this.currentDate = new Date();

    dateAdapter.setLocale("en-in");

    this.range = new FormGroup({
      start: new FormControl('', Validators.compose([])),
      end: new FormControl('', Validators.compose([])),
    });
    
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  nameValue(sort: any, sortValue: any) {
    this.selectedOption = sort;
    this.payload.sort = sortValue;

  }

  applyDate() {

    this.isNameApply = true;
    this.sharedService.sortByName({ ...this.payload, from: this.from });
    console.log(this.isNameApply);

  }

  removeDateFilter() {
    this.isNameApply = false;
    this.selectedOption = null;
    this.payload.sort = '',

    this.sharedService.sortByName({ ...this.payload, from: this.from });

    this.stopPropagation(event);
  };

  ngOnInit() {

    this.payload.sort = '',
    this.isNameApply = false;
    this.selectedOption = null;

    this.sharedService.sortByName({ ...this.payload, from: this.from });

    if (this.refreshCall) {
      this.refreshCall.subscribe(() => {

        this.selectedOption = null;
        this.isNameApply = false;
        this.payload.sort = '';

        this.sharedService.sortByName({ ...this.payload, from: this.from });
      });
    }

  }


}
