
<div class="">
    <button mat-flat-button class="new-sorting-btn"
      [matMenuTriggerFor]="customerMenuList" [ngClass]="{'active': isCusApply}">
      <div class="d-flex align-items-center">
        <div class=" " (click)="isCusApply && removeCusFilter()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1793_425)">
              <path
                d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00001C14.6663 4.31811 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.31811 1.33301 8.00001C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z"
                stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8 5.33334V10.6667" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.33301 8H10.6663" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_1793_425">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <span class="ms-2">Customer <span *ngIf="isCusApply">| </span></span>
        <ng-container *ngIf="isCusApply">
          <h6 class="m-0 ms-1">
            <span *ngFor="let name of customerName.slice(0, 2); let i = index">
              {{ name?.contact_first_name ? (name?.contact_first_name?.length > 5 ?
              name?.contact_first_name.substring(0,5) + '...' : name?.contact_first_name) :
              'N/A' }}
              <span *ngIf="i < customerName.slice(0, 2).length - 1">, </span>
            </span>
            <span *ngIf="customerName.length > 2">
              +{{ customerName.length - 2 }}
            </span>
          </h6>

        </ng-container>
      </div>
    </button>

    <div class="">
      <mat-menu #customerMenuList="matMenu" class="new-mat-menu mt-1" >
        <p class="mb-1">Filter by customer</p>

        <div class="position-relative w-100" [class.active]="isInputActive">
          <i class="fa fa-search position-absolute search-icon-btn" aria-hidden="true"
            [class.active]="isInputActive || isInputNotEmpty"></i>
          <input class="search-input-from-list" type="text" name="search" autocomplete="off"
            placeholder="Search by customer"
            [(ngModel)]="searchCustomer" (click)="stopPropagation($event)"
            [ngModelOptions]="{standalone: true}" (keyup)="applyFilter($event)">

          <div class="cursor-pointer position-absolute search-cross-icon" *ngIf="searchCustomer"
            (click)="newCustomerList(); stopPropagation($event); searchCustomer = null">
            <svg width="16" height="16" viewBox="0 0 649 649" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="325" cy="325" r="300" fill="#B7B7B7" fill-opacity="0.57" />
              <path
                d="M324.762 629.524C493.077 629.524 629.524 493.077 629.524 324.762C629.524 156.447 493.077 20 324.762 20C156.447 20 20 156.447 20 324.762C20 493.077 156.447 629.524 324.762 629.524Z"
                fill="#B7B7B7" fill-opacity="0.57" stroke="white" stroke-width="38.0952"
                stroke-linecap="round" stroke-linejoin="round" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M210.476 210.476L439.048 439.048Z"
                fill="#B7B7B7" fill-opacity="0.57" />
              <path d="M210.476 210.476L439.048 439.048" stroke="white" stroke-width="38.0952"
                stroke-linecap="round" stroke-linejoin="round" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M439.048 210.476L210.476 439.048Z"
                fill="#B7B7B7" fill-opacity="0.57" />
              <path d="M439.048 210.476L210.476 439.048" stroke="white" stroke-width="38.0952"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>


        <div class="items-ul  position-relative mt-3">
          <ul class="ps-0 mb-0">
            <li *ngFor="let item of customerList">
              <mat-checkbox #menuItems [(ngModel)]="item.isSelected" (click)="stopPropagation($event)"
                (change)="onCheckboxChange(item)" [value]="item.contact_id">
                {{item?.contact_first_name ? (item?.contact_first_name?.length > 20 ?
                item?.contact_first_name.substring(0,20) + '...' : item?.contact_first_name) :
                'N/A'}}
              </mat-checkbox>
            </li>
          </ul>
        </div>

        <button mat-flat-button class="btn blue-btn w-100 " [disabled]="customerId?.length < 1"
          (click)="filterByCustomer()">
          <div class="d-flex align-items-center justify-content-center">
            APPLY
          </div>
        </button>
      </mat-menu>
    </div>

  </div>
