export const apiRoutes = {
  login: 'auth/login',
  // registration: 'auth/org-user-register',
  registration: 'auth/users',
  resendLoginOTP: 'auth/generate-otp-login',
  signupOtp: 'auth/verifySignUpOtp',
  refreshToken: 'auth/new-token',
  verifyEmail: 'auth/verify-email',
  checkEmailexists: 'auth/check-email-exists',
  resetPassowrd: 'auth/reset-password',
  generateOTP: '/auth/verify-otp',
  mobileVerify: 'auth/generate-otp',
  forgotPassword: 'auth/reset-password',
  sendResetLink: 'auth/initiate-password-reset',
  verifyNewPassword: 'auth/verify-reset-pass-link',
  comfirmNewPassword: 'auth/confirm-password-reset',
  //COMPANY SELECTION / ADD
  orgProfile: 'admin/org-list',
  addOrgProfile:'admin/org-profile',
  selectedOrgProfile: 'admin/org-profile',
  deleteOrgProfile:'admin/org-bank-details-delete',
  updateOrgProfile: 'admin/org-profile',
  updateorgBankDetails: 'admin/org-bank-details',
  addorgBankDetails: 'admin/org-bank-details',
// DASHBOARD

  overDueDashboard: 'admin/dasdboard',

  // CUSTOMER
  addContact: 'admin/contact',
  getCustomer: 'admin/contact',
  updateCustomer: 'admin/contact',
  deleteCustomer: 'admin/contact',
  CustomerID: 'admin/customer-id',
  contactList: 'admin/contact-list',
  contactListSearch: 'admin/contact-list-search',
  contactVendorList: 'admin/contact-list-search',
  sortingCustomer: 'admin/contact-list-filter-sort',
  customerTrans: 'admin/contact-invoice-transaction',
  ladgerPdf: 'admin/contact-export-ledger',
  contactLadger: 'admin/contact-ledger-data',
  cusPaymentDown: 'admin/contact-payment-csv',
  //TEAM MANAGE
  addTeamProfile: 'admin/orgteamprofile',
  getOneMember: 'admin/orgteamprofile',
  updateTeamProfile: 'admin/orgteamprofile',
  deleteTeamProfile: 'admin/orgteamprofile',
  orgTeamProfileList: 'admin/orgteamprofile-list',
  teamListSearch: 'admin/orgteamprofile-search',
  memberCode:'admin/team-member-code',
  filterAndSortingTeam: 'admin/orgteamprofile-filter-sort',
  teamDetails: 'admin/orgteamprofile-details',
  downloadCusPDF: 'admin/contact-export-pdf',
  downloadCusCSV: 'admin/contact-export-csv',
  workOrderPayment: 'admin/contact-get-work-order-payments',
  // ITEM MASTER
  ProductDetails: 'admin/finish-product',
  getCodeProduct: 'admin/product-code',
  addItem: 'admin/finish-product',
  deleteItem: 'admin/finish-product',
  searchItem: 'admin/finish-product-search',
  updateItem: 'admin/finish-product',
  getIemList: 'admin/finish-product-list',
  categorySearch: 'admin/master-category-search',
  searchSupplierNoPage: 'admin/search-supplier-no-page',
  searchMasterUom: 'admin/search-master-uom',
  filterItem: 'admin/finish-product-filter-sort',
  itemSortFilterSearch: 'admin/finish-product-filter-sort',

  //SKU
  sortingItem: 'admin/product-per-sku-sort-filter',
  updateProductSku: 'admin/product-per-sku',
  deleteProductSku: 'admin/product-per-sku',
  searchProductSku: 'admin/product-per-sku',
  getSKU: 'admin/product-per-sku',
  // SKU LIST
  allProductskuList: 'admin/product-per-sku-list',
  searchProductskuList: 'admin/product-per-sku-list',
  // SELL MANAGEMENT
  addCustomer: 'admin/iqp-invoice-number',
  skuList: 'admin/product-per-sku-list',
  searchInvoiceCustomer: 'admin/iqp-customer-search',
  searchSKU: 'admin/product-per-sku-list-search',
  invoiceListSortingAndFilter: 'admin/iqp-item-sort-filter',
  sellQuarter: 'admin/iqp-item-dashboard-quarter',
  sellSummaryDashboard: 'admin/iqp-item-dashboard',
  sellPayment: 'admin/iqp-item-dashboard-payment',


  //INVOICE
  addInvoice:'admin/iqp',
  getInvoiceList: 'admin/iqp-list',
  generatePdf: 'admin/iqp-generate-pdf',
  invoicecustomerSearch: 'admin/iqp-customer-search',
  getOneIqp: 'admin/iqp',
  deleteIqp: 'admin/iqp',
  customerInvoice: 'admin/iqp-customer',
  updateInvoice: 'admin/iqp',
  sellListSearch: 'admin/iqp-item-search',
  //PAYMENT
  amountPayment: 'admin/iqp-amountPayment',
  amountList: 'admin/iqp-amountPayment-list',
  paymentDown: 'admin/iqp-paymenet-csv-export',

  //Invoice Generator
  invoiceNumberStrategy: 'admin/invoice-number-strategy',
  // WORK ODER
  orderId: 'admin/work-order-number',
  addWorkOrder: 'admin/work-order',
  getWorkOrderList: 'admin/work-order-list',
  detailsWorkOrder: 'admin/work-order-details',
  workOrderSchedule: 'admin/work-order-schedule-list',
  getOrderClone: 'admin/work-order-clone',
  //WORK ORDER SERVICE
  serviceNumber: 'admin/work-order-service-number',
  addEditListWorkOrderService: 'admin/work-order-service',
  detailsWorkOrderService: 'admin/work-order-service-details',
  listWorkOrderService:'admin/work-order-service-list',
  worOrderFinancial:'admin/work-order-financial-list',
  workOrderSearchEmp: 'admin/work-order-search-emp',
  workOrderSearchProduct: 'admin/work-order-search-product',
  workOrderServiceSchedule: 'admin/work-order-service-schedule-list',
  workOrderDashboard: 'admin/work-order-dashboard',
  workOrderDashboardSpecific: 'admin/work-order-dashboard-specific',
  // DIVISION
  division: 'admin/divisions',

}
