import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';
import { LoaderService } from 'src/app/shared/services/loader-service/loader-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { jsPDF } from "jspdf";
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as html2pdf from 'html2pdf.js';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentPopupComponent } from 'src/app/main/modules/sell-management/components/payment-popup/payment-popup.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatMenu } from '@angular/material/menu';
import Chart from 'chart.js/auto';
import { MainService } from 'src/app/main/services/main.service';
import { DateAdapter } from '@angular/material/core';
import { SharedService } from '../../services/shared-service/shared.service';
import { SellService } from 'src/app/main/modules/sell-management/service/sell.service';

export interface DialogData {
  id: any;
}

@Component({
  selector: 'app-new-date-filter',
  templateUrl: './new-date-filter.component.html',
  styleUrls: ['./new-date-filter.component.scss']
})
export class NewDateFilterComponent {


  @ViewChild(MatSelect) matSelect: MatSelect;
  @ViewChild(MatMenu) menu: MatMenu;
  orgId: any;

  currentDate: Date;
  @Input() type: any;
  @Input() from: any;
  @Input() refreshCall: EventEmitter<void>;
  @Output() dateApplied = new EventEmitter<void>();

  range: any;
  csvStartDate: any = '';
  csvEndDate: any = '';
  cusEnd: Date;
  cusStart: Date;
  totalData: any = null;
  tableDate: any = null;
  selectedOption: string;
  DatepickOpen: boolean = false;
  isDateApply: boolean = false;
  payload = {
    start: '',
    end: ''
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    public dialog: MatDialog,
    public mainService: MainService,
    private dateAdapter: DateAdapter<Date>,
    private sharedService: SharedService,
    public sellService: SellService,
    
  ) {
    this.orgId = this.authService.getId();
    this.currentDate = new Date();

    dateAdapter.setLocale("en-in");

    this.range = new FormGroup({
      start: new FormControl('', Validators.compose([])),
      end: new FormControl('', Validators.compose([])),
    });
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  calenderValue(date: string) {
    const today = new Date();
    this.selectedOption = date;
    this.range.value.start = null;
    this.range.value.end = null;
    this.csvStartDate = null;
    this.csvEndDate = null;
    this.cusStart = null;
    this.cusEnd = null;

    let startDate: Date;
    let endDate: Date;

    if (date === 'Current Month') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // last day of the current month
      this.DatepickOpen = false;
      this.cusStart = startDate;
      this.cusEnd = endDate;
      this.csvStartDate = this.formatDateToYYYYMMDD(startDate);
      this.csvEndDate = this.formatDateToYYYYMMDD(endDate);

      // this.getInvoiceList();


    }
    else if (date === 'Last Month') {
      const lastMonth = today.getMonth() - 1;
      startDate = new Date(today.getFullYear(), lastMonth, 1);
      endDate = new Date(today.getFullYear(), lastMonth + 1, 0); // last day of last month
      this.DatepickOpen = false;
      this.cusStart = startDate;
      this.cusEnd = endDate;
      this.csvStartDate = this.formatDateToYYYYMMDD(startDate);
      this.csvEndDate = this.formatDateToYYYYMMDD(endDate);

      // this.getInvoiceList();

    }
    else if (date === 'Current Quarter') {
      const currentMonth = today.getMonth();
      const quarter = Math.floor(currentMonth / 3);
      startDate = new Date(today.getFullYear(), quarter * 3, 1);
      endDate = new Date(today.getFullYear(), (quarter + 1) * 3, 0);
      this.DatepickOpen = false;
      this.cusStart = startDate;
      this.cusEnd = endDate;
      this.csvStartDate = this.formatDateToYYYYMMDD(startDate);
      this.csvEndDate = this.formatDateToYYYYMMDD(endDate);

      // this.getInvoiceList();

    }
    else if (date === 'Last Quarter') {
      const currentMonth = today.getMonth();
      let quarter = Math.floor(currentMonth / 3);
      if (quarter === 0) {
        quarter = 3;
        startDate = new Date(today.getFullYear() - 1, quarter * 3, 1);
        endDate = new Date(today.getFullYear(), 0, 0);
      } else {
        startDate = new Date(today.getFullYear(), (quarter - 1) * 3, 1);
        endDate = new Date(today.getFullYear(), quarter * 3, 0);
      }
      this.DatepickOpen = false;
      this.cusStart = startDate;
      this.cusEnd = endDate;
      this.csvStartDate = this.formatDateToYYYYMMDD(startDate);
      this.csvEndDate = this.formatDateToYYYYMMDD(endDate);

      // this.getInvoiceList();

    }
    else if (date === 'Current FY') {
      const currentYear = today.getFullYear();
      if (today.getMonth() + 1 < 4) {
        startDate = new Date(currentYear - 1, 3, 1);
        endDate = new Date(currentYear, 2, 31);
        this.cusStart = startDate;
        this.cusEnd = endDate;
      } else {
        startDate = new Date(currentYear, 3, 1);
        endDate = new Date(currentYear + 1, 2, 31);

        this.cusStart = startDate;
        this.cusEnd = endDate;
      }
      this.DatepickOpen = false;
      this.csvStartDate = this.formatDateToYYYYMMDD(startDate);
      this.csvEndDate = this.formatDateToYYYYMMDD(endDate);

      // this.getInvoiceList();

    }
    else if (date === 'Last FY') {
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1; // JavaScript months are 0-based

      if (currentMonth < 4) { // If the current month is before April

        startDate = new Date(currentYear - 2, 3, 1); // 1st April two years ago
        endDate = new Date(currentYear - 1, 2, 31); // 31st March last year
      } else {

        startDate = new Date(currentYear - 1, 3, 1); // 1st April last year
        endDate = new Date(currentYear, 2, 31); // 31st March this year
      }


      this.DatepickOpen = false;
      this.csvStartDate = this.formatDateToYYYYMMDD(startDate);
      this.csvEndDate = this.formatDateToYYYYMMDD(endDate);

      // this.getInvoiceList();

    }
    if (date === 'Custom Date') {
      this.range = new FormGroup({
        start: new FormControl(null, Validators.compose([])),
        end: new FormControl(null, Validators.compose([])),
      });

      this.cusStart = null;
      this.cusEnd = null;
      this.csvStartDate = null;
      this.csvEndDate = null;
      startDate = null;
      endDate = null;
      this.DatepickOpen = true;
    }
    this.isDateApply = false;

  }

  customDate() {
    if (!this.range.value.start) {
      this.alertService.warningAlert('You must choose a start date!');
      return;
    };

    if (!this.range.value.end) {
      this.alertService.warningAlert('You must choose a end date!');

      return;
    }
    const startDate = new Date(this.range.value.start);
    const endDate = new Date(this.range.value.end);
    this.csvStartDate = this.formatDateToYYYYMMDD(startDate);
    this.csvEndDate = this.formatDateToYYYYMMDD(endDate);

  }

  applyDate() {
    // this.sharedService.csvStartDate = this.csvStartDate;
    // this.sharedService.csvEndDate = this.csvEndDate;
    this.isDateApply = true;

    let payload ={
      start:this.csvStartDate, 
      end:  this.csvEndDate,
    }
    this.payload = payload;

    this.sharedService.setDateFilter({ ...this.payload, from: this.from });
    console.log(this.isDateApply);


  }

  formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  removeDateFilter() {
    this.DatepickOpen = false;
    this.selectedOption = null;
    this.csvStartDate = '';
    this.csvEndDate = '';
    this.isDateApply = false;
 
     this.payload.start = this.csvStartDate;
     this.payload.end =   this.csvEndDate,
    
    this.sharedService.setDateFilter({ ...this.payload, from: this.from });

    this.stopPropagation(event);
  };

  ngOnInit() {

    this.sharedService.csvStartDate = this.csvStartDate;
    this.sharedService.csvEndDate = this.csvEndDate;

    this.payload.start = this.csvStartDate;
    this.payload.end =   this.csvEndDate,
    this.sharedService.setDateFilter({ ...this.payload, from: this.from });

    if (this.refreshCall) {
      this.refreshCall.subscribe(() => {
        this.csvEndDate = '';
        this.csvStartDate = '';
        this.isDateApply = false;
        this.selectedOption = null;

        this.sharedService.csvStartDate = this.csvStartDate;
        this.sharedService.csvEndDate = this.csvEndDate;
    
        this.payload.start = this.csvStartDate;
        this.payload.end =   this.csvEndDate,
        this.sharedService.setDateFilter({ ...this.payload, from: this.from });
            });
    }

    }

  
}
