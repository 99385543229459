import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';
import { LoaderService } from 'src/app/shared/services/loader-service/loader-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { jsPDF } from "jspdf";
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as html2pdf from 'html2pdf.js';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentPopupComponent } from 'src/app/main/modules/sell-management/components/payment-popup/payment-popup.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatMenu } from '@angular/material/menu';
import Chart from 'chart.js/auto';
import { MainService } from 'src/app/main/services/main.service';
import { DateAdapter } from '@angular/material/core';
import { SharedService } from 'src/app/shared/services/shared-service/shared.service';
import { SellService } from 'src/app/main/modules/sell-management/service/sell.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-selection',
  templateUrl: './customer-selection.component.html',
  styleUrls: ['./customer-selection.component.scss']
})
export class CustomerSelectionComponent {
  @ViewChild(MatSelect) matSelect: MatSelect;
  @ViewChild(MatMenu) menu: MatMenu;
  @Output() refreshCalled: EventEmitter<void> = new EventEmitter();


  isInputActive: boolean = false;
  searchTerm: string = '';
  searchCustomer: string;
  orgId: any = null;
  invoiceListData: any = null;
  profitFilter: any;
  invoiceParamsId: any;
  isEditMode: boolean;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  totalListCount: any;
  isLoading: boolean = false;
  overFilter: any;
  customerId: any = [];
  customerName: any = [];

  isCusApply: boolean = false;

  today: Date = new Date();

  paidAmount: any;
  paymentForm: FormGroup;
  currentDate: Date;
  paymentValue: any;
  customerList: any;
  noCus: boolean;
  dueDateOver: boolean = false;
  searchItem: any = '';
  paidNewAmount: void;
  payload: any = {
    id: [],
    details: []
  }
  @Input() customerType: any;
  @Input() customerFrom: any;
  @Input() customerefreshCall: EventEmitter<void>;


  constructor(
    private apiCallMethodsService: ApiCallMethodsService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    public mainService: MainService,
    private dateAdapter: DateAdapter<Date>,
    public sellManageService: SellService,
    private sharedService: SharedService,

  ) {
    this.orgId = this.authService.getId();
    this.currentDate = new Date();

    dateAdapter.setLocale("en-in");




    this.paymentForm = this.formBuilder.group({
      iqp_payment_type: new FormControl('', Validators.compose([])),
      iqp_payment_ref: new FormControl('', Validators.compose([])),
      iqp_paid_date: new FormControl(this.currentDate, Validators.compose([])),
      notes: new FormControl('', Validators.compose([])),
      iqp_partly_pmnt_amnt: new FormControl('', Validators.compose([Validators.required])),
    });




    // this.getInvoiceList();

    this.newCustomerList();

  }


  getInvoiceList(value: any = null) {

    if (value == 2) {
      this.isCusApply = true;
    }


  };


  newCustomerList() {
    let url = apiRoutes.customerInvoice + '/' + this.orgId;
    this.apiCallMethodsService.get(url).subscribe((response: any) => {
      this.customerList = response?.customer;

    })

  }

  filterByCustomer() {
    this.isCusApply = true;
    this.payload.id = this.customerId;
    this.payload.details = this.customerName;

    this.sharedService.selectedCustomerFilter({ ...this.payload, customerFrom: this.customerFrom });

  }




  onInputFocus() {
    this.isInputActive = true;
  }

  onInputBlur() {
    this.isInputActive = false;
  }


  onCheckboxChange(selectedItem: any) {
    if (selectedItem.isSelected) {
      this.customerId.push(selectedItem.contact_id);
      this.customerName.push(selectedItem);


    } else {
      this.customerId = this.customerId.filter(id => id !== selectedItem.contact_id);
      this.customerName = this.customerName.filter(id => id !== selectedItem);
    }
    this.isCusApply = false;
  }


  deSelectFilter() {
    this.customerList?.forEach(item => {
      item.isSelected = false;
    });
  }


  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchCustomer = filterValue;
    if (this.searchCustomer.trim() === '') {
      this.newCustomerList();
      return;
    }
    let payload = {
      "reg": this.searchCustomer,
    }
    let url = apiRoutes.searchInvoiceCustomer + '/' + this.orgId;
    this.apiCallMethodsService.post(url, payload).subscribe((response: any) => {
      this.customerList = response?.customer;
      this.noCus = !this.customerList || this.customerList.length === 0;
    })

  };


  removeCusFilter() {
    this.customerName = [];
    this.customerId = [];
    this.stopPropagation(event);
    this.isCusApply = false;
    this.searchCustomer = null;
    this.newCustomerList();
    this.deSelectFilter();
    this.payload.id = this.customerId;
    this.payload.details = this.customerName;

    this.sharedService.selectedCustomerFilter({ ...this.payload, customerFrom: this.customerFrom });

  };

  ngOnInit() {

    this.customerName = [];
    this.customerId = [];
    this.isCusApply = false;
    this.searchCustomer = null;
    this.newCustomerList();
    this.deSelectFilter();
    this.payload.id = this.customerId;
    this.payload.details = this.customerName;

    this.sharedService.selectedCustomerFilter({ ...this.payload, customerFrom: this.customerFrom });

    if (this.customerefreshCall) {
      this.customerefreshCall.subscribe(() => {
        this.customerName = [];
        this.customerId = [];
        this.isCusApply = false;
        this.searchCustomer = null;
        this.newCustomerList();
        this.deSelectFilter();
        this.payload.id = this.customerId;
        this.payload.details = this.customerName;

        this.sharedService.selectedCustomerFilter({ ...this.payload, customerFrom: this.customerFrom });
      })

    }
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  get isInputNotEmpty(): boolean {
      return this.searchTerm.trim() !== '';
    }

  }
