
<div class="">
    <button mat-flat-button class="new-sorting-btn start-sm-0" [matMenuTriggerFor]="dateList"
      [ngClass]="{'active': isDateApply}" (click)="!isDateApply && calenderValue('Current Month')">
      <div class="d-flex align-items-center">
        <div class=" " (click)="isDateApply && removeDateFilter()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1793_425)">
              <path
                d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00001C14.6663 4.31811 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.31811 1.33301 8.00001C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z"
                stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8 5.33334V10.6667" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.33301 8H10.6663" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_1793_425">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <span class="ms-2">Status <span *ngIf="isDateApply">| </span></span>

        <h6 class="m-0 ms-1" *ngIf="isDateApply"> {{selectedOption}}</h6>
      </div>
    </button>

    <div>
      <mat-menu #dateList="matMenu" class="new-mat-menu mt-1">

        <p class="mb-1">Filter by status</p>
        <div class="">
          <mat-form-field class="header-select-box nav-md bell-btn w-100" appearance="outline"
            (click)="stopPropagation($event)">
            <mat-select [(ngModel)]="selectedOption" placeholder="Select">
              <mat-option class="custom-height-menu" value="Current Month"
                (click)="calenderValue('Current Month')">
                Collecting
              </mat-option>
              <mat-option class="custom-height-menu" value="Last Month" (click)="calenderValue('Last Month')">
                Demo
              </mat-option>
              <mat-option class="custom-height-menu" value="Current Quarter"
                (click)="calenderValue('Current Quarter')">
                Demo
              </mat-option>
              <mat-option class="custom-height-menu" value="Last Quarter"
                (click)="calenderValue('Last Quarter')">
                Demo
              </mat-option>
              <mat-option class="custom-height-menu" value="Current FY" (click)="calenderValue('Current FY')">
                Demo
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="d-flex align-items-center mt-2 DatepickOpen" *ngIf="DatepickOpen">
            <div class="w-20">

              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="me-2 ms-3">
                <path d="M12.5 8.33331L16.6667 12.5L12.5 16.6666" stroke="#0386FF" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M3.33301 3.33331V9.16665C3.33301 10.0507 3.6842 10.8985 4.30932 11.5237C4.93444 12.1488 5.78229 12.5 6.66634 12.5H16.6663"
                  stroke="#0386FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>

            <div class="w-80">

              <div class="date-picker-custom w-100">
                <mat-form-field class="header-select-box w-100" (click)="stopPropagation($event)">
                  <mat-date-range-input [formGroup]="range" [rangePicker]="picker" readonly
                    (click)="picker.open()">
                    <input matStartDate formControlName="start" placeholder="Start date"
                    readonly (click)="picker.open()">

                    <input matEndDate formControlName="end" placeholder="End date" readonly (click)="picker.open()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>

                  <mat-date-range-picker #picker>
                    <mat-date-range-picker-actions>
                      <button class="secondary-btn date mx-2" mat-button matDateRangePickerCancel
                        *ngIf="!rangeData">Cancel</button>
                      <button class="secondary-btn date mx-2" mat-button matDateRangePickerCancel
                        (click)="cancelBtn()" *ngIf="rangeData">Cancel</button>
                      <button class="primary-btn date" mat-raised-button color="primary"
                        (click)="customDate()" matDateRangePickerApply>Apply</button>
                    </mat-date-range-picker-actions>
                  </mat-date-range-picker>


                </mat-form-field>

              </div>
            </div>
          </div>



          <button mat-flat-button class="btn blue-btn w-100 mt-4" [disabled]="!csvEndDate"
            (click)="applyDate()">
            <div class="d-flex align-items-center justify-content-center">
              APPLY
            </div>
          </button>

        </div>

      </mat-menu>
    </div>

  </div>