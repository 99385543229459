<div class="position-relative btn-position" [ngClass]="{'active-btn': isToggled}">
    <!-- <div class="position-absolute toggle-side-btn cursor-pointer" (click)="toggle()"
        [ngClass]="{'hidden': isToggled && sidebarHovered}"> -->
    <div class="position-absolute toggle-side-btn cursor-pointer" (click)="toggle()">
        <mat-icon class="burger-menu" class="">keyboard_arrow_right</mat-icon>
    </div>
    <div class="side-navbar-new position-relative">

        <div class="sidebar-area bg-white box-shadow" [ngClass]="{'active': isToggled}"
            [class.hide-sidebar]="themeService.isHideSidebar()" (mouseenter)="onSidebarHover()"
            (mouseleave)="onSidebarLeave()">

            <div class="logo">
                <a routerLink="/" class="d-flex align-items-center w-100">
                    <img src="../../../../assets/images/logo.png" alt="logo" class="w-100">
                </a>

            </div>
            <div class=" ">
                <div class="logo-small">
                    <a routerLink="/" class="d-flex align-items-center w-100">
                        <img src="../../../../assets/images/short_logo.png" alt="logo" class="w-100">
                    </a>
                </div>
                <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
                    <mat-icon class="toggle-btn">clear</mat-icon>

                </div>

            </div>

            <div visibility="hover" style="height: 100vh;" class="mat-drawer">
                <div class="sidebar-inner">
                    <div class="sidebar-menu">
                        <mat-form-field class="header-select-box nav-md comapnay-name" appearance="outline">
                            <mat-select placeholder="Select Name" [(value)]="orgId" (valueChange)="onOrgChange()">
                                <mat-option value="{{company.org_id}}" *ngFor="let company of companyData">
                                    {{company.org_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="main-menu-content ps ps--active-y">
                            <mat-nav-list class="navigation navigation-main" id="main-menu-navigation">
                                <mat-list-item routerLink="admin/dashboard" routerLinkActive="active-nav"
                                    class=" text-color nav-item  p-0">
                                    <a class="d-flex align-items-center" id="dashboard">
                                        <!-- <img [src]="isActive(['/admin/dashboard']) ? '../../../../assets/images/dahsboard-purple.png' : '../../../../assets/images/dashboard-deep-blue.png'"
                                            alt=""> -->
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"
                                            width="24" class="svg-color me-0">
                                            <path
                                                d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h240v-560H200v560Zm320 0h240v-280H520v280Zm0-360h240v-200H520v200Z" />
                                        </svg>
                                        <p class="menu-title text-truncate ps-3 mb-0 " data-i18n="Dashboards">Dashboard
                                        </p>
                                    </a>
                                </mat-list-item>

                            </mat-nav-list>
                        </div>
                        <div class="main-menu-content ps ps--active-y">
                            <mat-nav-list class="navigation navigation-main" id="main-menu-navigation">
                                <mat-list-item class="nav-item p-0" routerLinkActive="active-nav"
                                    [routerLinkActiveOptions]="{exact: false}"
                                    [routerLink]="['/admin/business-contacts']">
                                    <a class="d-flex align-items-center" id="contacts">

                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"
                                            width="24" class="svg-color me-0">
                                            <path
                                                d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm0-80h640v-440H160v440Zm240-520h160v-80H400v80ZM160-200v-440 440Z" />
                                        </svg>

                                        <p class="menu-title text-truncate ps-3 mb-0" data-i18n="contacts">Business
                                            Contacts
                                        </p>
                                    </a>
                                    <a hidden [routerLink]="'/admin/supplier-details-profile'"> </a>
                                    <a hidden [routerLink]="'/admin/customer-details-profile'"> </a>

                                </mat-list-item>
                            </mat-nav-list>
                        </div>
                        <div class="main-menu-content ps ps--active-y">
                            <mat-nav-list class="navigation navigation-main" id="main-menu-navigation">
                                <mat-list-item class="nav-item p-0" routerLinkActive="active-nav"
                                    [routerLinkActiveOptions]="{exact: false}" [routerLink]="['/admin/items-master',]">
                                    <a class="d-flex align-items-center" id="contacts">

                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"
                                            width="24" class="svg-color me-0">
                                            <path
                                                d="m400-570 80-40 80 40v-190H400v190ZM280-280v-80h200v80H280Zm-80 160q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-640v560-560Zm0 560h560v-560H640v320l-160-80-160 80v-320H200v560Z" />
                                        </svg>
                                        <p class="menu-title text-truncate ps-3 mb-0" data-i18n="contacts">Products &
                                            Services</p>
                                    </a>
                                    <a hidden [routerLink]="'/admin/item-details'"> </a>

                                </mat-list-item>
                            </mat-nav-list>
                        </div>


                        <div class="main-menu-content ps ps--active-y">
                            <mat-nav-list class="navigation navigation-main" id="main-menu-navigation">
                                <mat-list-item class="nav-item p-0" routerLink="admin/team-management"
                                    routerLinkActive="active-nav">
                                    <a class="d-flex align-items-center" id="contacts">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"
                                            width="24" class="svg-color me-0">
                                            <path
                                                d="M350-63q-46 0-82.5-24T211-153q-16 21-40.5 32.5T120-109q-51 0-85.5-35T0-229q0-43 28-77.5T99-346q-14-20-21.5-42.5T70-436q0-40 20.5-75t57.5-57q5 18 13.5 38.5T181-494q-14 11-22 26.5t-8 32.5q0 56 46 69t87 21l19 32q-11 32-19 54.5t-8 40.5q0 30 21.5 52.5T350-143q38 0 63-34t41-80q16-46 24.5-93t13.5-72l78 21q-9 45-22 103t-36.5 110.5Q488-135 449.5-99T350-63ZM120-189q17 0 28.5-11.5T160-229q0-17-11.5-28.5T120-269q-17 0-28.5 11.5T80-229q0 17 11.5 28.5T120-189Zm284-158q-46-41-83.5-76.5t-64.5-69q-27-33.5-41.5-67T200-629q0-65 44.5-109.5T354-783q4 0 7 .5t7 .5q-4-10-6-20t-2-21q0-50 35-85t85-35q50 0 85 35t35 85q0 11-2 20.5t-6 19.5h14q60 0 102 38.5t50 95.5q-18-3-40.5-3t-41.5 2q-7-23-25.5-38T606-703q-35 0-54.5 20.5T498-623h-37q-35-41-54.5-60.5T354-703q-32 0-53 21t-21 53q0 23 13 47.5t36.5 52q23.5 27.5 57 58.5t74.5 67l-57 57Zm76-436q17 0 28.5-11.5T520-823q0-17-11.5-28.5T480-863q-17 0-28.5 11.5T440-823q0 17 11.5 28.5T480-783ZM609-63q-22 0-43.5-6T524-88q11-14 22-33t20-35q11 7 22 10t22 3q32 0 53.5-22.5T685-219q0-19-8-41t-19-54l19-32q42-8 87.5-21t45.5-69q0-40-29.5-58T716-512q-42 0-98 16t-131 41l-21-78q78-25 139-42t112-17q69 0 121 41t52 115q0 25-7.5 47.5T861-346q43 5 71 39.5t28 77.5q0 50-34.5 85T840-109q-26 0-50.5-11.5T749-153q-20 42-56.5 66T609-63Zm232-126q17 0 28-11.5t11-28.5q0-17-11.5-29T840-270q-17 0-28.5 11.5T800-230q0 17 12 29t29 12Zm-721-40Zm360-594Zm360 593Z" />
                                        </svg>
                                        <p class="menu-title text-truncate ps-3 mb-0" data-i18n="contacts">Team
                                            Management
                                        </p>
                                    </a>
                                    <a hidden [routerLink]="'/admin/team-management-details'"> </a>

                                </mat-list-item>
                            </mat-nav-list>

                        </div>

                        <div class="main-menu-content ps ps--active-y">
                            <mat-nav-list class="navigation navigation-main" id="main-menu-navigation">
                                <mat-list-item class="nav-item p-0" routerLinkActive="active-nav"
                                    [routerLinkActiveOptions]="{exact: false}"
                                    [routerLink]="['/admin/sell-management',]">
                                    <a class="d-flex align-items-center" id="contacts">

                                        <!-- <img [src]="isActive(['/admin/sell-management']) ? '../../../../assets/images/Business contacts-purple.png' : '../../../../assets/images/Business contacts.png'"
                                            alt=""> -->
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"
                                            width="24" class="svg-color me-0">
                                            <path
                                                d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z" />
                                        </svg>
                                        <p class="menu-title text-truncate ps-3 mb-0" data-i18n="contacts">Sell &
                                            Payment
                                        </p>
                                    </a>
                                </mat-list-item>
                            </mat-nav-list>
                        </div>
                        <div class="main-menu-content ps ps--active-y">
                            <mat-nav-list class="navigation navigation-main" id="main-menu-navigation">
                                <mat-list-item class="nav-item p-0" routerLinkActive="active-nav"
                                    [routerLinkActiveOptions]="{exact: false}" [routerLink]="['/admin/work-order',]">
                                    <a class="d-flex align-items-center" id="contacts">

                                        <!-- <img [src]="isActive(['/admin/work-order']) ? '../../../../assets/images/Business contacts-purple.png' : '../../../../assets/images/Business contacts.png'"
                                            alt=""> -->
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"
                                            width="24" class="svg-color me-0">
                                            <path
                                                d="M320-280q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280Zm0-160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440Zm0-160q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600Zm120 320h240v-80H440v80Zm0-160h240v-80H440v80Zm0-160h240v-80H440v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z" />
                                        </svg>
                                        <p class="menu-title text-truncate ps-3 mb-0" data-i18n="contacts">Work Orders
                                        </p>
                                    </a>
                                    <a hidden [routerLink]="'/admin/work-order-details'"></a>
                                </mat-list-item>
                            </mat-nav-list>
                        </div>
                        <div class="main-menu-content ps ps--active-y">
                            <mat-nav-list class="navigation navigation-main" id="main-menu-navigation">
                                <mat-list-item class="nav-item p-0" routerLinkActive="active-nav"
                                    [routerLinkActiveOptions]="{exact: false}"
                                    [routerLink]="['/admin/setting-details',]">
                                    <a class="d-flex align-items-center" id="contacts">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960"
                                            width="24" class="svg-color me-0">
                                            <path
                                                d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z" />
                                        </svg>
                                        <p class="menu-title text-truncate ps-3 mb-0" data-i18n="contacts">Settings
                                        </p>
                                    </a>
                                </mat-list-item>
                            </mat-nav-list>
                        </div>

                        <div class="main-menu-content ps ps--active-y">
                            <mat-nav-list class="navigation navigation-main" id="main-menu-navigation">
                                <mat-list-item class="nav-item p-0" routerLinkActive="active-nav"
                                    [routerLinkActiveOptions]="{exact: false}"
                                    [routerLink]="['admin/tax-tds/tax-overview']">
                                    <a class="d-flex align-items-center" id="tax-tds">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg" class="svg-path-color me-0">
                                            <path d="M9 14L15 8" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path
                                                d="M9.5 9C9.77614 9 10 8.77614 10 8.5C10 8.22386 9.77614 8 9.5 8C9.22386 8 9 8.22386 9 8.5C9 8.77614 9.22386 9 9.5 9Z"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path
                                                d="M14.5 14C14.7761 14 15 13.7761 15 13.5C15 13.2239 14.7761 13 14.5 13C14.2239 13 14 13.2239 14 13.5C14 13.7761 14.2239 14 14.5 14Z"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path
                                                d="M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21Z"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>


                                        <p class="menu-title text-truncate ps-3 mb-0" data-i18n="contacts">Tax & TDS
                                        </p>
                                    </a>
                                </mat-list-item>
                            </mat-nav-list>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>