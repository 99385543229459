import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
  dateFilterUpdated: EventEmitter<any> = new EventEmitter<any>();
  customerFilterUpdated: EventEmitter<any> = new EventEmitter<any>();
  customerSortUpdated: EventEmitter<any> = new EventEmitter<any>();

  csvStartDate: any = '';
  csvEndDate: any = '';

  constructor() { }


  setDateFilter(filter: any) {    
    this.dateFilterUpdated.emit(filter);
  }

  selectedCustomerFilter(filter: any) {    
    this.customerFilterUpdated.emit(filter);
  }

  
  sortByName(filter: any) {    
    this.customerSortUpdated.emit(filter);
  }

}
