
<div class="">
    <button mat-flat-button class="new-sorting-btn start-sm-0" [matMenuTriggerFor]="dateList"
      [ngClass]="{'active': isNameApply}" (click)="!isNameApply && nameValue('A to Z', 'ASC')">
      <div class="d-flex align-items-center">
        <div class=" " (click)="isNameApply && removeDateFilter()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1793_425)">
              <path
                d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00001C14.6663 4.31811 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.31811 1.33301 8.00001C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z"
                stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8 5.33334V10.6667" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.33301 8H10.6663" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_1793_425">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <span class="ms-2">Name <span *ngIf="isNameApply">| </span></span>

        <h6 class="m-0 ms-1" *ngIf="isNameApply"> {{selectedOption}}</h6>
      </div>
    </button>

    <div>
      <mat-menu #dateList="matMenu" class="new-mat-menu mt-1" xPosition="before">

        <p class="mb-1">Sort by name</p>
        <div class="">
          <mat-form-field class="header-select-box nav-md bell-btn w-100" appearance="outline"
            (click)="stopPropagation($event)">
            <mat-select [(ngModel)]="selectedOption" placeholder="Select">
              <mat-option class="custom-height-menu" value="A to Z"
                (click)="nameValue('A to Z', 'ASC')">
                A to Z
              </mat-option>
              <mat-option class="custom-height-menu" value="Z to A" (click)="nameValue('Z to A', 'DESC')">
                Z to A
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-flat-button class="btn blue-btn w-100 mt-4" [disabled]="!selectedOption"
            (click)="applyDate()">
            <div class="d-flex align-items-center justify-content-center">
              APPLY
            </div>
          </button>

        </div>

      </mat-menu>
    </div>

  </div>