<div class="header-border">
    <div class="" *ngIf="showHeader">
        <div class="main-content">

            <nav
                class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow">

                <div class="navbar-container d-flex content w-100 align-items-center justify-content-between">
                    <div class="pt-50 cursor-pointer ml-2 side-btn">
                                           
                    </div>
                    
                    <img src="../../../../assets/images/Logo01-small.png" alt="" class="logo-sm toggle-btn">


                    <div class="nav navbar-nav align-items-center  flexs justify-content-end ">


                        <mat-form-field class="header-select-box nav-md bell-btn " appearance="outline">
                            <mat-select placeholder="Select Name" [(value)]="orgId"
                                (valueChange)="setSelectedOrg(orgId, getOrgName(orgId))">
                                <mat-option value="{{company.org_id}}" *ngFor="let company of companyData">
                                    {{company.org_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>


                        <div mat-icon-button [matMenuTriggerFor]="profileMenu"
                            class="profile-btn main-color border-none bg-transparent position-relative cursor-pointer">
                            <img src="{{ userDetails?.user?.org_image_link }}" class="rounded-circle" alt="admin"
                                *ngIf="userDetails?.user?.org_image_link; else noDpImage">
                            <ng-template #noDpImage>
                                <img src="../../../../assets/images/empty-user-picture.png" class="rounded-circle"
                                    alt="admin">
                            </ng-template>
                            {{getSellerDetails?.org1?.org_category}}
                            <span class="fw-semibold">{{getSellerDetails?.org1?.org_name}}</span>
                        </div>
                        <mat-menu class="profile-menu-dropdown  sorting-menu" #profileMenu="matMenu" xPosition="after">
                            <ul class="m-0 p-0">
                                <li class="list-down sku-list">
                                    <div routerLink="/admin/my-profile">
                                        <div class="d-flex align-items-center ">
                                            <!-- <img src="../../../../assets/images/logout.svg" alt=""> -->
                                            <p class="menu-title text-truncate header-menu mb-0">
                                                My Profile
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <!-- <li>
                                <a routerLink="/privacy-policy">
                                    <i class="ri-account-circle-line"></i>Account
                                </a>
                            </li> -->
                                <li class="list-down cursor-pointer" (click)="redirectToBusinessManagement()">
                                    <a>
                                        <p class="menu-title text-truncate header-menu mb-0"> Add another company</p>
                                    </a>
                                </li>
                                <li class="list-down cursor-pointer" (click)="logout()">
                                    <a>
                                        <div class="d-flex align-items-center">
                                            <img src="../../../../assets/images/logout.svg" alt="" class="dropdown-img">
                                            <p class="menu-title text-truncate header-menu mb-0">
                                                Logout
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </mat-menu>
                        <!-- <button mat-button (click)="logOut()">
                        LOGOUT
                        <mat-icon>power_settings_new</mat-icon>
                    </button> -->
                        <div class=" toggle-btn toggle-btn-display">
                            <mat-icon class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}"
                                class="">menu</mat-icon>
                        </div>
                    </div>

                </div>
            </nav>
        </div>
    </div>
</div>