import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';
import { LoaderService } from 'src/app/shared/services/loader-service/loader-service.service';
import { SharedService } from 'src/app/shared/services/shared-service/shared.service';

@Injectable({
  providedIn: 'root'
})
export class SellService {
  orgId: any = null;
  invoiceListData: any = null;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  // isLoading: boolean = false;

  csvStartDate: any = '';
  csvEndDate: any = '';
  sellPageIndex: number = 0;
  sellPageSize: number = 10;
  SharingData = new Subject();
  startFilterDate: any = null;
  endFilterDate: any = null;
  payload: any = {};

  //PAYMENT
  paymentList: any = null;
  paymentNewList: any = null;
  totalListCount: any = null;
  paymentPayload: any = {};
  paymentPageIndex: number = 0;
  paymentPageSize: number = 10;

  constructor(
    private authService: AuthService,
    private loaderService: NgxUiLoaderService,
    private apiCallMethodsService: ApiCallMethodsService,
    private sharedService: SharedService,

  ) {
    this.orgId = this.authService.getId();

    this.sharedService.dateFilterUpdated.subscribe((data: any) => {
      console.log(data);
      this.csvStartDate = data?.start;
      this.csvEndDate = data?.end;

      if (this.invoiceListData && data?.from == 'sell-list') {
        this.payload.date_range.start = data?.start;
        this.payload.date_range.end = data?.end;

        this.getInvoiceList(0, 10, this.payload);
      }

      if (this.paymentList && data?.from == 'payment-list') {
        this.paymentPayload.startDate = data?.start;
        this.paymentPayload.endDate = data?.end;

        this.getNewPaymentList(0, 10, this.paymentPayload);
      }

    });

    this.sharedService.customerFilterUpdated.subscribe((data: any) => {

      console.log(data);

      if( this.invoiceListData && data?.customerFrom == 'cus-sell-list'){
        this.payload.customer = data?.id;
        this.getInvoiceList(0, 10, this.payload);

      }

      if( this.paymentList && data?.customerFrom == 'payment-sell-list'){
        this.paymentPayload.customer = data?.id;
        this.getNewPaymentList(0, 10, this.paymentPayload);

      }

    })
  }


  getInvoiceList(pageNumber, rowsPerPage, payload: any = null) {
    this.sellPageIndex = pageNumber;
    this.sellPageSize = rowsPerPage;
    this.payload = payload;

    // this.isLoading = true;
    // this.loaderService.start();

    // let strucPayload = {
    //   "list": "",
    //   "reg": "",
    //   "invoice_status": this.invoiceStatus,
    //   "date_range": {
    //     "start":  payload?.start,
    //     "end": this.csvEndDate,
    //   },
    //   "invoice_amount": {
    //     "under": this.profitFilter,
    //     "over": this.overFilter,
    //   },
    //   "customer": payload.customer ? payload.customer : [],
    //   "payment_status": "",
    //   "invoice_issue_date": "",
    //   "invoice_due_date": "",
    //   "invoice_number": "",
    //   "customer_name": "",
    //   "customer_location": "",
    //   "total_amount": "",
    //   "over_due": "",
    // };

    let url = `${apiRoutes.invoiceListSortingAndFilter}/${this.orgId}?page=${pageNumber}&pagesize=${rowsPerPage}`;

    this.apiCallMethodsService.post(url, payload).subscribe((response: any) => {
      // this.isLoading = false;
      // this.loaderService.stop();

      let data;


      this.invoiceListData = response;

      data = {
        invoiceData: this.invoiceListData,
      }


      this.SharingData.next(data);

    },
      (error: any) => {

        let data = {
          attendence: this.invoiceListData,
        }

        this.SharingData.next(data);

      });

  };

  getNewPaymentList(pageNumber, pageSize, newPayload: any = null) {

    this.paymentPageIndex = pageNumber;
    this.paymentPageSize = pageSize;
    this.paymentPayload = newPayload

    let url = `${apiRoutes.sellPayment}/${this.orgId}?page=${pageNumber}&pagesize=${pageSize}`;

    // let payload = {
    //   "startDate": this.csvStartDate,
    //   "endDate": this.csvEndDate,
    //   "customer": []
    // }

    this.apiCallMethodsService.post(url, newPayload).subscribe((response: any) => {

      let data;


      this.paymentList = response;

      data = {
        paymentData: this.paymentList,
      }


      this.SharingData.next(data);

    },
      (error: any) => {

        let data = {
          paymentData: this.paymentList,
        }

        this.SharingData.next(data);

      });
  }
}
