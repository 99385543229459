import { Routes } from "@angular/router";
import { LogoutGuardService } from "src/app/guards/logout-guard/logout-guard.service";
import { LoginComponent } from "../components/login/login.component";
import { ResetPasswordComponent } from "../components/reset-password/reset-password.component";
import { SignUpComponent } from "../components/sign-up/sign-up.component";
import { CreatePasswordComponent } from "../components/create-password/create-password.component";
import { VerifyOtpComponent } from "../components/verify-otp/verify-otp.component";
import { SelectCompanyComponent } from "../components/select-company/select-company.component";
import { CompanyRegisterComponent } from "../components/company-register/company-register.component";

export const authRoutes: Routes = [
    {
        path: '',
        redirectTo:'login',
        pathMatch: 'full'
    },
    {
      path: 'login',
      component: LoginComponent,
     canActivate: [LogoutGuardService]
    },
    {
      path: 'reset-password',
      component: ResetPasswordComponent,
    },
    {
      path: 'create-password',
      component: CreatePasswordComponent,
    },
    {
      path: 'signup',
      component: SignUpComponent,
    },
    {
      path: 'verify-otp',
      component: VerifyOtpComponent,
    },
    {
        path: 'select-company',
        component: SelectCompanyComponent,
    },
    {
      path: 'company-register',
      component: CompanyRegisterComponent
     },
]
