import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private apiCallMethodsService: ApiCallMethodsService) { 
    
  }



    downloadPdf(invoice: any, pdfLink: any) {
      let fileUrl = `${apiRoutes.generatePdf}/${invoice?.iqp_id}`;
      const data = {
        path: pdfLink,
        iqp_invoice_number: invoice?.iqp_invoice_number,
      };
      this.apiCallMethodsService.postPdf(fileUrl, data).subscribe((response: any) => {
        let blob = new Blob([response.body], { type: 'application/pdf' });
        let pdfUrl = window.URL.createObjectURL(blob);
        let fileName = response.headers;
        var PDF_link = document.createElement('a');
        PDF_link.href = pdfUrl;
        window.open(pdfUrl, '_blank');
  
        PDF_link.download = invoice?.iqp_invoice_number;
        PDF_link.click();
      });
    }
}
